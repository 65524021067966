import {
  createFileRoute,
  useNavigate,
  useParams,
  useSearch,
} from "@tanstack/react-router";
import { queryKeys, useApiPut, useApiQuery } from "../../../../../lib/api";
import {
  Button,
  Card,
  Column,
  Divider,
  HelperTooltip,
  Icon,
  Row,
  Text,
  useDesignTokens,
} from "@gradience/ui";
import { useSelectedTest } from "../../../../../domain/use-latest-test";
import PageChrome from "../../../../../components/page-chrome";
import { IconContainer } from "@gradience/ui/dist/components/icon";
import ConfigureCurricula from "./curricula";
import ConfigureChapters from "./chapters";
import styled from "styled-components";
import toast from "../../../../../domain/toast";
import ConfigureConcepts, { useConceptsSelection } from "./concepts";
import { useEffect, useMemo, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";

type ConfigureGroupPageSearch = {
  selectedCurricula: string[] | undefined;
  selectedChapters: string[] | undefined;
  // Concepts are automatically active based on the selected chapters, but users
  // can manually select or deselect concepts
  selectedConcepts: string[] | undefined;
  deselectedConcepts: string[];
  step: number;
  loadedGroup?: boolean;
};

export const Route = createFileRoute("/$test-slug/groups/$id/configure/")({
  component: ConfigureGroupPage,
  validateSearch: (search): ConfigureGroupPageSearch => {
    const validatedSearch: ConfigureGroupPageSearch = {
      selectedCurricula: undefined,
      selectedChapters: undefined,
      selectedConcepts: undefined,
      deselectedConcepts: [],
      step: 0,
    };

    for (const key of [
      "selectedCurricula",
      "selectedChapters",
      "selectedConcepts",
      "deselectedConcepts",
    ] as const) {
      if (key in search && Array.isArray(search[key])) {
        validatedSearch[key] = search[key];
      }
    }

    if ("step" in search && typeof search.step === "number") {
      validatedSearch.step = search.step;
    }

    if ("loadedGroup" in search && search.loadedGroup === "true") {
      validatedSearch.loadedGroup = true;
    }

    return validatedSearch;
  },
});

function ConfigureGroupPage() {
  const params = useParams({ from: "/$test-slug/groups/$id/configure/" });
  const groupsQuery = useApiQuery("/groups", {});
  const designTokens = useDesignTokens();
  const navigate = useNavigate({ from: "/$test-slug/groups/$id/configure" });

  const group = useMemo(
    () => groupsQuery.data?.data.find((group) => group.id === params.id),
    [groupsQuery.data?.data, params.id]
  );

  const savedGroupCurriculumChapters = useApiQuery(
    "/chapters",
    {},
    {},
    {
      curriculumIds: group?.curriculumIds,
    }
  );

  const test = useSelectedTest();

  const loading =
    groupsQuery.isLoading ||
    test.isLoading ||
    savedGroupCurriculumChapters.isLoading;

  const { selectedCurricula, selectedChapters, step, loadedGroup } = useSearch({
    from: "/$test-slug/groups/$id/configure/",
  });

  const { selectedConcepts, numberOfQuestionsQuery } = useConceptsSelection();

  useEffect(() => {
    // When the page first loads, set up the query params if the group has been
    // configured
    if (
      !groupsQuery.isLoading &&
      !test.isLoading &&
      !savedGroupCurriculumChapters.isLoading &&
      group?.conceptIds &&
      savedGroupCurriculumChapters.data?.data &&
      !loadedGroup
    ) {
      const expectedSelectedConcepts =
        savedGroupCurriculumChapters.data?.data
          .filter((chapter) => group?.curriculumChapterIds.includes(chapter.id))
          .flatMap((chapter) => {
            return chapter.conceptIds;
          }) ?? [];
      // Additional = concepts - expected
      const additionalSelectedChapters = group.conceptIds.filter(
        (conceptId) => !expectedSelectedConcepts.includes(conceptId)
      );
      // Deselected = expected - concepts
      const deselectedConcepts = expectedSelectedConcepts.filter(
        (conceptId) => !group.conceptIds.includes(conceptId)
      );

      navigate({
        search: (prev) => ({
          ...prev,
          selectedConcepts: additionalSelectedChapters,
          deselectedConcepts,
          selectedChapters: group.curriculumChapterIds,
          selectedCurricula: group.curriculumIds,
          loadedGroup: true,
        }),
      }).then(() => numberOfQuestionsQuery.refetch());
    }
  }, [
    group,
    groupsQuery.isLoading,
    navigate,
    numberOfQuestionsQuery,
    savedGroupCurriculumChapters.data?.data,
    savedGroupCurriculumChapters.isLoading,
    test.isLoading,
    loadedGroup,
  ]);

  const setStep = (step: ConfigureGroupPageSearch["step"]) => {
    navigate({
      search: (prev) => ({
        ...prev,
        step,
      }),
    });
  };

  const Component = useMemo(() => {
    let Component: React.FC = () => <></>;

    if (step === 0) {
      Component = ConfigureCurricula;
    }

    if (step >= 1 && step < (selectedCurricula ?? []).length + 1) {
      Component = () => <ConfigureChapters step={step} />;
    }

    if (step === (selectedCurricula ?? []).length + 1) {
      Component = () => <ConfigureConcepts />;
    }

    return Component;
  }, [step, selectedCurricula]);

  const conceptGroupsQuery = useApiQuery(
    "/concept-groups",
    {},
    {
      enabled: test.isSuccess,
    },
    {
      testId: test.data?.id!,
    }
  );
  const queryClient = useQueryClient();

  const updateGroupMutation = useApiPut("/groups/:id", {
    id: params.id,
  });

  return (
    <PageChrome loading={loading}>
      <Column style={{ gap: 40, flex: 1 }}>
        <Row
          gap={16}
          style={{
            alignItems: "center",
          }}
        >
          <Row
            gap={8}
            style={{
              alignItems: "flex-start",
              flex: 1,
            }}
          >
            <Icon
              name="arrow-left"
              to={`/${test.data?.yearIdentifier}/groups/${group?.id}`}
            />
            <Column>
              <Text textStyle="32px - Bold">{group?.name}</Text>
              <Text textStyle="14px - Semibold">
                Instructor {group?.instructorFirstName}{" "}
                {group?.instructorLastName}
              </Text>
            </Column>
          </Row>
          {step !== 0 ? (
            <Button
              text="Back"
              size="small"
              onPress={() => {
                setStep(step - 1);
              }}
            />
          ) : null}
          <Button
            text={
              step === (selectedCurricula ?? []).length + 1 ? "Finish" : "Next"
            }
            variant="primary"
            size="small"
            to={
              // Only links away if this is the final step
              step === (selectedCurricula ?? []).length + 1
                ? `/${test.data?.yearIdentifier}/groups/${group?.id}`
                : undefined
            }
            onPress={async () => {
              if (step === (selectedCurricula ?? []).length + 1) {
                await updateGroupMutation.mutateAsync({
                  conceptIds: selectedConcepts,
                  curriculumChapterIds: selectedChapters,
                  curriculumIds: selectedCurricula,
                });
                queryClient.invalidateQueries({
                  queryKey: queryKeys["/groups"],
                });
              } else {
                setStep(step + 1);
              }
            }}
          />
        </Row>
        <Row
          gap={20}
          style={{ alignItems: "flex-start", position: "relative" }}
        >
          <Component />
          <Column
            style={{
              gap: 16,
              flexBasis: 306,
              flexGrow: 0,
              flexShrink: 0,
              position: "sticky",
              top: 40,
            }}
          >
            <Card
              style={{
                padding: 24,
                gap: 24,
              }}
            >
              <Row style={{ gap: 16, alignItems: "center" }}>
                <IconContainer>
                  <Icon
                    size={24}
                    name="list"
                    color={designTokens.colors.icon.brand}
                  />
                </IconContainer>

                <Text textStyle="20px - Bold">Tested Concepts</Text>
                <HelperTooltip>
                  <Column gap={8}>
                    <Text textStyle="caption">
                      The type and number of questions on the ULE changes based
                      on which concepts your students studied throughout the
                      year.
                    </Text>
                    <Text textStyle="caption">
                      Once you have selected curricula and chapters, applicable
                      concepts will be shown here.
                    </Text>
                  </Column>
                </HelperTooltip>
              </Row>
              <Column gap={16}>
                {(selectedChapters ?? []).length === 0 &&
                (selectedConcepts ?? []).length === 0 ? (
                  <Row
                    style={{
                      backgroundColor: designTokens.colors.surface.Subdued,
                      padding: 8,
                      borderRadius: 12,
                    }}
                  >
                    <Text
                      textStyle="14px - Medium"
                      style={{
                        color: designTokens.colors.text.disabled,
                        textAlign: "center",
                      }}
                    >
                      Waiting for curriculum & chapter selection...
                    </Text>
                  </Row>
                ) : (
                  conceptGroupsQuery.data?.data.map((conceptGroup) => {
                    return (
                      <ConceptGroupSelections
                        conceptGroup={conceptGroup}
                        selectedConcepts={selectedConcepts}
                      />
                    );
                  })
                )}
              </Column>
              <Divider />
              <Row gap={4}>
                <Text textStyle="14px - Medium" style={{ flex: 1 }}>
                  # of Test Questions
                </Text>
                <Text textStyle="14px - Bold">
                  {numberOfQuestionsQuery.data?.numberOfQuestions ?? 0}
                </Text>
              </Row>
            </Card>
          </Column>
        </Row>
      </Column>
    </PageChrome>
  );
}

const ConceptGroupSelections = ({
  conceptGroup,
  selectedConcepts,
}: {
  conceptGroup: {
    id: string;
    name: string;
    concepts: { id: string; name: string }[];
  };
  selectedConcepts: string[];
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const concepts = conceptGroup.concepts.filter((concept) =>
    selectedConcepts.includes(concept.id)
  );

  const designTokens = useDesignTokens();

  if (concepts.length === 0) {
    return null;
  }

  return (
    <Column key={conceptGroup.id}>
      <Row
        style={{
          cursor: "pointer",
          marginBottom: 4,
        }}
        gap={8}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Text textStyle="14px - Bold">{conceptGroup.name}</Text>
        <span style={{ flex: 1 }}>
          <span
            style={{
              backgroundColor: designTokens.colors.surface.Dark,
              borderRadius: 8,
              padding: "2px 6px",
            }}
          >
            <Text
              textStyle="caption"
              style={{
                minHeight: 16,
                color: designTokens.colors.text.inverted,
              }}
            >
              {concepts.length}
            </Text>
          </span>
        </span>
        <Icon
          name={isOpen ? "chevron-up" : "chevron-down"}
          onClick={() => setIsOpen(!isOpen)}
          noButtonStyle
        />
      </Row>
      {isOpen && (
        <Column>
          {concepts.map((concept) => (
            <Concept concept={concept} />
          ))}
        </Column>
      )}
    </Column>
  );
};

const ConceptContainer = styled(Row)`
  padding: 4px 8px;
  border-radius: 4px;
  align-items: center;

  .concept-x {
    opacity: 0;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.surface.Subdued};

    .concept-x {
      opacity: 1;
    }
  }
`;

const Concept = ({ concept }: { concept: { id: string; name: string } }) => {
  const { selectConcept, deselectConcept } = useConceptsSelection();

  return (
    <ConceptContainer>
      <Text key={concept.id} textStyle="14px - Medium" style={{ flex: 1 }}>
        {concept.name}
      </Text>
      <Icon
        name="x-02"
        size={16}
        className="concept-x"
        onClick={() => {
          deselectConcept(concept.id);
          toast({
            message: `"${concept.name}" was removed`,
            icon: "list",
            action: {
              label: "Undo",
              onClick: () => selectConcept(concept.id),
            },
          });
        }}
        noButtonStyle
      />
    </ConceptContainer>
  );
};
