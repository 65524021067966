import { useNavigate, useParams, useSearch } from "@tanstack/react-router";
import GroupConfigurationModal from "../../../forms/group-configuration-modal";

const EditGroupModal = () => {
  const search = useSearch({ from: "/$test-slug/groups/$id/" });
  const params = useParams({ from: "/$test-slug/groups/$id/" });
  const navigate = useNavigate({ from: "/$test-slug/groups/$id" });

  return (
    <GroupConfigurationModal
      open={search.groupModal === "edit"}
      groupId={params.id}
      onClose={() => {
        navigate({
          search: (prev) => ({ ...prev, groupModal: undefined }),
        });
      }}
    />
  );
};

export default EditGroupModal;
