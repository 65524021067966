import { Text, Button, Input } from "@gradience/ui";
import {
  createFileRoute,
  useNavigate,
  useSearch,
} from "@tanstack/react-router";
import { useState } from "react";
import { LOGGED_IN_USER_KEY, useApiPost } from "../lib/api";
import AuthChrome from "../components/auth-chrome";
import { setToken } from "../lib/auth";
import { useQueryClient } from "@tanstack/react-query";
import { useLatestTest } from "../domain/use-latest-test";

type ResetPasswordRouteSearch = {
  token: string;
  initialSetup?: boolean;
};
export const Route = createFileRoute("/reset-password")({
  component: ResetPassword,
  validateSearch: (search): ResetPasswordRouteSearch => {
    const validatedSearch: ResetPasswordRouteSearch = {
      token: "",
    };

    if (typeof search.initialSetup === "string") {
      validatedSearch.initialSetup = search.initialSetup === "true";
    }

    if (typeof search.token === "string") {
      validatedSearch.token = search.token;
    }

    return validatedSearch;
  },
});

function ResetPassword() {
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const latestTest = useLatestTest();

  const { token, initialSetup } = useSearch({
    from: "/reset-password",
  });
  const requestPasswordesetMutation = useApiPost("/auth/password-reset", {
    onSuccess: ({ token }) => {
      setToken(token);
      queryClient.invalidateQueries({ queryKey: LOGGED_IN_USER_KEY });
      navigate({
        to: "/$test-slug/home",
        params: {
          "test-slug": latestTest.data?.yearIdentifier ?? "",
        },
      });
    },
  });

  return (
    <AuthChrome>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          requestPasswordesetMutation.mutate({
            token,
            password: password,
          });
        }}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 32,
        }}
      >
        <Text textStyle="headingLarge">
          {initialSetup ? "Choose a" : "Reset"} Password
        </Text>
        <Input
          label={initialSetup ? "Password" : "New Password"}
          name="password"
          value={password}
          setValue={setPassword}
          type="password"
          required
        />
        <div
          style={{
            display: "flex",
            gap: 32,
            flexDirection: "row",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Button
            variant="primary"
            text={initialSetup ? "Set password" : "Reset Password"}
            size="large"
            type="submit"
          />
          {!initialSetup && (
            <Text textStyle="caption" to="/log-in">
              Remembered? <b>Sign In</b>
            </Text>
          )}
        </div>
      </form>
    </AuthChrome>
  );
}

export default ResetPassword;
