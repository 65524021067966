import { useQuery } from "@tanstack/react-query";
import { ResponseForPath, postApi } from "../lib/api";

/**
 * Normally would just use `useApiQuery` but this is a post request since
 * concept ids gets too long for a query string
 */
const useNumberOfQuestions = (
  conceptIds: string[] | undefined,
  testId: string | undefined
) =>
  useQuery<ResponseForPath<"/number-of-questions", "post">, unknown>({
    queryKey: [["/number-of-questions"], conceptIds],
    queryFn: async () => {
      return await postApi("/number-of-questions", {
        conceptIds: conceptIds ?? [],
        testId: testId ?? "",
      });
    },
    enabled: conceptIds?.length && testId ? conceptIds?.length > 0 : false,
  });

export default useNumberOfQuestions;
