import {
  Button,
  ClickableText,
  Column,
  Loader,
  Modal,
  Row,
  Text,
  useDesignTokens,
} from "@gradience/ui";
import { useNavigate, useSearch } from "@tanstack/react-router";
import useSchool from "../../../lib/use-school";
import { hasPaymentMethod } from "../../../domain/is-school-info-complete";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useState } from "react";
import { SetupIntent, StripeError } from "@stripe/stripe-js";
import {
  queryKeys,
  useApiDelete,
  useApiPost,
  useApiPut,
} from "../../../lib/api";
import { useQueryClient } from "@tanstack/react-query";

const BillingMethodModal = () => {
  const search = useSearch({ from: "/$test-slug/home/" });
  const navigate = useNavigate({ from: "/" });
  const designTokens = useDesignTokens();
  const school = useSchool();

  const [stripeError, setStripeError] = useState<StripeError | undefined>();
  const stripe = useStripe();
  const elements = useElements();
  const postSetupIntent = useApiPost("/setup-intents");
  const putSchool = useApiPut("/school");
  const deleteSchoolPaymentMethod = useApiDelete("/school/payment-method");

  const queryClient = useQueryClient();

  const onClose = () =>
    navigate({ search: (prev) => ({ ...prev, modal: undefined }) });
  const isOpen = search.modal === "billing-method";

  const submitting = postSetupIntent.isPending || putSchool.isPending;

  const submitForm = async () => {
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error: submitError } = await elements.submit();
    if (submitError) {
      return;
    }

    const res = await postSetupIntent.mutateAsync({});

    const { clientSecret } = res;

    let error: StripeError | undefined = undefined;
    let setupIntent: SetupIntent | undefined = undefined;

    // Confirm the SetupIntent using the details collected by the Payment Element
    const confirmSetupResult = await stripe.confirmSetup({
      elements,
      clientSecret,
      confirmParams: {
        return_url: "https://example.com/complete",
      },
      redirect: "if_required",
    });
    error = confirmSetupResult.error;
    setupIntent = confirmSetupResult.setupIntent;

    if (error) {
      setStripeError(error);
    } else {
      await putSchool.mutateAsync({
        ...(setupIntent
          ? {
              paymentMethod:
                typeof setupIntent.payment_method === "string"
                  ? { id: setupIntent.payment_method }
                  : undefined,
            }
          : {}),
      });

      queryClient.invalidateQueries({ queryKey: queryKeys["/school"] });

      onClose();
    }
  };

  return (
    <Modal open={isOpen} close={onClose}>
      {school.isLoading ? (
        <Loader />
      ) : (
        <form
          key={isOpen ? "open" : "closed"}
          onSubmit={(e) => {
            e.preventDefault();
            submitForm();
          }}
          style={{ flex: 1, gap: 32, display: "flex", flexDirection: "column" }}
        >
          <Row gap={16}>
            <Text textStyle="24px - Bold" style={{ flex: 1 }}>
              {hasPaymentMethod(school.data)
                ? "Edit Billing Info"
                : "Add Billing Info"}
            </Text>
            {hasPaymentMethod(school.data) && school.data?.numberOfStudents ? (
              <ClickableText
                style={{ color: designTokens.colors.text.brand }}
                type="button"
                onClick={async () => {
                  if (
                    !window.confirm(
                      "Are you sure you want to cancel this order? This will remove your billing info and you will not be charged for any students you’ve added."
                    )
                  ) {
                    return;
                  }

                  await deleteSchoolPaymentMethod.mutateAsync({
                    body: {},
                  });
                  queryClient.invalidateQueries({
                    queryKey: queryKeys["/school"],
                  });
                  onClose();
                }}
              >
                Cancel Order
              </ClickableText>
            ) : null}
          </Row>
          <Text>
            <Text>
              You will be charged on or around March 1 when tests become
              available.
            </Text>
          </Text>
          <Column gap={8} style={{ flex: 1 }}>
            <Text textStyle="16px - Semibold">Billing Information*</Text>
            <Text textStyle="14px - Medium">
              Curious about alternative payment methods? Please email{" "}
              <a
                href="email:uleinfo@instituteforclassicallanguages.org"
                style={{
                  color: designTokens.colors.text.brand,
                  textDecoration: "none",
                  fontWeight: "bold",
                }}
              >
                uleinfo@instituteforclassicallanguages.org
              </a>
              .
            </Text>
            <PaymentElement />
            {stripeError && (
              <Text style={{ color: designTokens.colors.text.brand }}>
                {stripeError.message}
              </Text>
            )}
          </Column>
          <Row gap={16}>
            <Button
              type="submit"
              disabled={!stripe}
              loading={submitting}
              text="Add"
              variant="primary"
              size="large"
            />
            <Button
              type="button"
              text="Cancel"
              disabled={submitting}
              linkProps={{
                search: (prev) => ({ ...prev, modal: undefined }),
              }}
              size="large"
            />
          </Row>
        </form>
      )}
    </Modal>
  );
};

export default BillingMethodModal;
