import rhtToast from "react-hot-toast";
import { Column, getDesignTokens, Row, Text } from "@gradience/ui";
import config from "../lib/config";
import Icon, {
  IconContainer,
  IconName,
} from "@gradience/ui/dist/components/icon";
import UnstyledButtonWrapper from "../components/unstyled-button-wrapper";
import { motion } from "framer-motion";

const toast = ({
  message,
  icon,
  action,
}: {
  message: string;
  icon: IconName;
  action?: { label: string; onClick: () => unknown };
}) => {
  const designTokens = getDesignTokens(config.VITE_TENANT_THEME_COLOR);

  rhtToast.custom(
    (t) => (
      <motion.div
        initial={{ opacity: 0, x: 50 }}
        animate={{ opacity: t.visible ? 1 : 0, x: t.visible ? 0 : 50 }}
        exit={{ opacity: 0, x: 50 }}
        transition={{ duration: 0.3 }}
        className="custom-toast"
        style={{ pointerEvents: "auto" }}
      >
        <Row
          className="custom-toast"
          style={{
            gap: 16,
            padding: 24,
            border: `1px solid ${designTokens.colors.border.light}`,
            borderRadius: 16,
            backgroundColor: designTokens.colors.surface.Background,
            animation: "fade-in 0.2s",
            transition: "all 0.2s",
          }}
        >
          <IconContainer>
            <Icon
              name={icon}
              color={designTokens.colors.icon.brand}
              size={24}
            />
          </IconContainer>
          <Column gap={8}>
            <Text textStyle="16px - Semibold">{message}</Text>
            <Row gap={6}>
              {action && (
                <>
                  <UnstyledButtonWrapper
                    onClick={() => {
                      action?.onClick();
                      rhtToast.dismiss(t.id);
                    }}
                  >
                    <Text textStyle="14px - Semibold">{action?.label}</Text>
                  </UnstyledButtonWrapper>
                  <Text textStyle="14px - Semibold">∙</Text>
                </>
              )}
              <UnstyledButtonWrapper onClick={() => rhtToast.dismiss(t.id)}>
                <Text textStyle="14px - Semibold">Dismiss</Text>
              </UnstyledButtonWrapper>
            </Row>
          </Column>
        </Row>
      </motion.div>
    ),
    {
      position: "bottom-right",
      duration: 5000,
    }
  );
};

export default toast;
