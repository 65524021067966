var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import Checkbox from "./checkbox";
import Text from "./text";
var ItemWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  gap: 8px;\n  padding: 16px;\n  border-radius: 12px;\n  cursor: pointer;\n  background-color: ", ";\n  border: ", ";\n  &:hover {\n    background-color: ", ";\n  }\n"], ["\n  display: flex;\n  flex-direction: row;\n  gap: 8px;\n  padding: 16px;\n  border-radius: 12px;\n  cursor: pointer;\n  background-color: ", ";\n  border: ", ";\n  &:hover {\n    background-color: ", ";\n  }\n"])), function (_a) {
    var selected = _a.selected, theme = _a.theme;
    return selected ? theme.colors.surface.Subdued : "transparent";
}, function (_a) {
    var selected = _a.selected, theme = _a.theme;
    return selected
        ? "1px solid ".concat(theme.colors.border.subdued)
        : "1px solid transparent";
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.surface.Subdued;
});
var ChecklistItem = function (_a) {
    var label = _a.label, value = _a.value, onChange = _a.onChange;
    return (_jsxs(ItemWrapper, { style: {
            padding: "9px 17px",
        }, selected: value !== null && value !== void 0 ? value : false, onClick: function () { return onChange === null || onChange === void 0 ? void 0 : onChange(!value); }, children: [_jsx(Checkbox, { value: value }), _jsx(Text, { children: label })] }));
};
export default ChecklistItem;
var templateObject_1;
