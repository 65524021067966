import {
  createFileRoute,
  Link,
  useNavigate,
  useParams,
} from "@tanstack/react-router";
import PageChrome from "../../../../components/page-chrome";
import {
  Badge,
  Button,
  Card,
  Column,
  DataPoint,
  DropdownButton,
  Icon,
  Loader,
  MeatballMenu,
  Row,
  Text,
  useDesignTokens,
} from "@gradience/ui";
import {
  queryKeys,
  useApiDelete,
  useApiPost,
  useApiQuery,
} from "../../../../lib/api";
import { IconContainer, IconName } from "@gradience/ui/dist/components/icon";
import {
  useLatestTest,
  useSelectedTest,
} from "../../../../domain/use-latest-test";
import { useHover } from "@uidotdev/usehooks";
import AddStudentsModal from "../add-students-modal";
import EditGroupModal from "../edit-group-modal";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Student } from "@gradience/api-types";
import { useCallback, useMemo, useState } from "react";
import EditStudentModal from "../edit-student-modal";
import { isDatePast } from "../../../../lib/date-utils";
import toast from "../../../../domain/toast";
import { useQueryClient } from "@tanstack/react-query";
import stripTimeZone from "../../../../lib/strip-time-zone";
import useNumberOfQuestions from "../../../../domain/use-number-of-questions";

const groupModals = ["add-students", "edit", "edit-student"] as const;
type GroupRouteSearch = {
  groupModal?: (typeof groupModals)[number];
  editingStudentId?: string;
};

export const Route = createFileRoute("/$test-slug/groups/$id/")({
  component: GroupPage,
  validateSearch: (search): GroupRouteSearch => {
    const validatedSearch: GroupRouteSearch = {};
    if (groupModals.includes(search.groupModal as any)) {
      validatedSearch.groupModal = search.groupModal as any;
    }
    if (
      search.editingStudentId &&
      typeof search.editingStudentId === "string"
    ) {
      validatedSearch.editingStudentId = search.editingStudentId;
    }

    return validatedSearch;
  },
});

const columnHelper = createColumnHelper<Student>();

function GroupPage() {
  const params = useParams({ from: "/$test-slug/groups/$id/" });
  const groupsQuery = useApiQuery(
    "/groups",
    {},
    {
      refetchInterval: (result) => {
        const group = result?.state?.data?.data.find(
          (group) => group.id === params.id
        );
        console.log(group?.isGeneratingStudyGuide ? 1000 : 0);
        console.log("generating?", group?.isGeneratingStudyGuide);
        console.log("group", group);
        console.log("params", params);
        console.log("params.id", params.id);
        console.log(result);
        return group?.isGeneratingStudyGuide ? 1000 : 0;
      },
    }
  );
  const designTokens = useDesignTokens();
  const navigate = useNavigate({ from: "/$test-slug/groups/$id" });

  const group = useMemo(
    () => groupsQuery.data?.data.find((group) => group.id === params.id),
    [groupsQuery.data?.data, params.id]
  );
  const test = useSelectedTest();
  const latestTest = useLatestTest();

  const loading = groupsQuery.isLoading || test.isLoading;

  const students = useApiQuery("/students", {}, undefined, {
    groupId: params.id,
  });
  const deleteStudent = useApiDelete("/student-lists");

  const onDelete = useCallback(
    async (id: string) => {
      if (window.confirm("Are you sure you want to delete this student?")) {
        await deleteStudent.mutateAsync({
          body: {
            studentIds: [id],
          },
        });
        students.refetch();
      }
    },
    [deleteStudent, students]
  );

  const studentTableColumns = useMemo(
    () => [
      columnHelper.accessor("firstName", {
        header: "Name",
        cell: ({ row }) => `${row.original.firstName} ${row.original.lastName}`,
      }),
      columnHelper.accessor("grade", {}),
      columnHelper.accessor("score.adjustedScore", {
        header: "Score",
        cell: ({ row }) => row.original.score?.adjustedScore ?? "—",
      }),
      columnHelper.accessor("score.percentile", {
        header: "Percentile",
        cell: ({ row }) => row.original.score?.percentile ?? "—",
      }),
      ...(isDatePast(test.data?.configurationDateEnd)
        ? []
        : [
            columnHelper.display({
              id: "actions",
              header: "Actions",
              cell: ({ row }) => (
                <MeatballMenu
                  options={[
                    { label: "Delete", value: "delete" },
                    { label: "Edit", value: "edit" },
                  ]}
                  onSelect={(option) => {
                    switch (option) {
                      case "delete":
                        onDelete(row.original.id);
                        break;
                      case "edit":
                        navigate({
                          search: (prev) => ({
                            ...prev,
                            groupModal: "edit-student",
                            editingStudentId: row.original.id,
                          }),
                        });
                        break;
                    }
                  }}
                />
              ),
            }),
          ]),
    ],
    [navigate, onDelete, test.data?.configurationDateEnd]
  );

  const data = useMemo(
    () => (students.data?.data ? [...students.data.data] : []),
    [students.data?.data]
  );

  const table = useReactTable({
    data,
    columns: studentTableColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  const newGroups = useApiQuery(
    "/groups",
    {},
    {},
    { testId: latestTest.data?.id }
  );
  const [copiedRoster, setCopiedRoster] = useState<string>();
  const createStudentsMutation = useApiPost("/student-lists");

  const queryClient = useQueryClient();

  const configurationAvailable =
    test.isLoading ||
    !test.data ||
    (isDatePast(test.data.configurationDateStart) &&
      !isDatePast(test.data.configurationDateEnd));

  const numberOfQuestions = useNumberOfQuestions(
    group?.conceptIds ?? [],
    test.data?.id
  );

  return (
    <PageChrome loading={loading}>
      <AddStudentsModal
        onSubmit={() => {
          // numberOfStudents will be updated
          groupsQuery.refetch();
        }}
      />
      <EditGroupModal />
      <EditStudentModal />
      <Column style={{ gap: 40, flex: 1 }}>
        <Row
          gap={16}
          style={{
            alignItems: "center",
          }}
        >
          <Row
            gap={8}
            style={{
              alignItems: "flex-start",
              flex: 1,
            }}
          >
            <Icon name="arrow-left" to={`/${test.data?.yearIdentifier}/home`} />
            <Column>
              <Text textStyle="32px - Bold">{group?.name}</Text>
              <Text textStyle="14px - Semibold">
                Instructor {group?.instructorFirstName}{" "}
                {group?.instructorLastName}
              </Text>
            </Column>
          </Row>
          {isDatePast(test.data?.configurationDateEnd) ? (
            <DropdownButton
              text={
                copiedRoster
                  ? `Copied to ${copiedRoster}`
                  : `Copy Roster to ${latestTest.data?.yearIdentifier} Group`
              }
              onSelect={async (groupId) => {
                const group = newGroups.data?.data.find(
                  (group) => group.id === groupId
                );
                if (!group) {
                  return;
                }
                await createStudentsMutation.mutateAsync({
                  groupId: group.id,
                  students:
                    students.data?.data.map((student) => ({
                      firstName: student.firstName,
                      lastName: student.lastName,
                      grade: student.grade,
                    })) ?? [],
                });
                setCopiedRoster(group.name);
                queryClient.invalidateQueries({
                  queryKey: queryKeys["/groups/:id"],
                });
                toast({
                  message: "Roster copied successfully.",
                  icon: "Students",
                  action: {
                    label: "Go To Group",
                    onClick: () => {
                      navigate({
                        to: "/$test-slug/groups/$id",
                        params: {
                          "test-slug": latestTest.data?.yearIdentifier,
                          id: groupId,
                        },
                      });
                    },
                  },
                });
              }}
              variant="subdued"
              size="small"
              icon={copiedRoster ? "check-02" : undefined}
              disabled={copiedRoster !== undefined}
              loading={createStudentsMutation.isPending}
              options={
                newGroups.data?.data.map((group) => ({
                  label: group.name,
                  value: group.id,
                })) ?? []
              }
            />
          ) : (
            <Button
              text="Edit Info"
              variant="subdued"
              size="small"
              to="?groupModal=edit"
            />
          )}
        </Row>
        <Column gap={20}>
          <Row gap={8}>
            <DataPoint
              icon="calendar-check"
              title={
                group?.testDate
                  ? stripTimeZone(new Date(group.testDate)).toLocaleDateString(
                      "en-US",
                      {
                        month: "short",
                        day: "numeric",
                        year: "numeric",
                      }
                    )
                  : "—"
              }
              subtitle="Exam Date"
            />
            <DataPoint
              icon="users-profile-03"
              title={group?.numberOfStudents?.toString() ?? ""}
              subtitle="Students"
            />
            <DataPoint
              icon="file-branch"
              title={group?.conceptIds?.length.toString() ?? ""}
              subtitle="Concepts Tested"
            />
            <DataPoint
              icon="file-question-02"
              title={numberOfQuestions.data?.numberOfQuestions.toString() ?? ""}
              subtitle="Exam Questions"
            />
          </Row>
          {latestTest.data?.id === test.data?.id ? (
            <Row gap={20}>
              <Card
                style={{
                  gap: 24,
                  flex: 1,
                }}
              >
                <div
                  style={{
                    flex: 1,
                    flexBasis: 4,
                    borderRadius: 2,
                    backgroundColor: designTokens.colors.misc.Brand,
                  }}
                />
                <Row gap={16} style={{ alignItems: "center" }}>
                  <IconContainer>
                    <Icon
                      name="file-edit-02"
                      color={designTokens.colors.icon.brand}
                      size={24}
                    />
                  </IconContainer>
                  <Text textStyle="20px - Bold" style={{ flex: 1 }}>
                    Set Up
                  </Text>
                  <Badge type="primary" icon="calendar-07">
                    {`Now – ${
                      test.data
                        ? new Date(
                            test.data.configurationDateEnd
                          ).toLocaleDateString("en-US", {
                            month: "short",
                            day: "numeric",
                            year: "numeric",
                          })
                        : ""
                    }`}
                  </Badge>
                </Row>
                <Column>
                  <ActionLink
                    title="Add Students"
                    icon="plus-01"
                    to="?groupModal=add-students"
                    badgeContent={`Before ${
                      test.data
                        ? new Date(
                            test.data.configurationDateEnd
                          ).toLocaleDateString("en-US", {
                            month: "short",
                            day: "numeric",
                          })
                        : ""
                    }`}
                  />
                  <ActionLink
                    title="Configure Tests"
                    icon="arrow-right"
                    to={`/${test.data?.yearIdentifier}/groups/${params.id}/configure`}
                    disabled={!configurationAvailable}
                    badgeContent={
                      configurationAvailable
                        ? `Before ${new Date(
                            test.data?.configurationDateEnd ?? ""
                          ).toLocaleDateString("en-US", {
                            month: "short",
                            day: "numeric",
                          })}`
                        : `Available ${
                            test.data
                              ? new Date(
                                  test.data.configurationDateStart
                                ).toLocaleDateString("en-US", {
                                  month: "short",
                                  day: "numeric",
                                })
                              : ""
                          }`
                    }
                  />
                  <ActionLink
                    newTab
                    to={group?.studyGuideUrl}
                    title="Download Study Guide"
                    icon="download-02"
                    disabled={!group?.studyGuideUrl}
                    badgeContent={
                      group && group.conceptIds.length > 0
                        ? group.isGeneratingStudyGuide
                          ? "Generating..."
                          : ""
                        : "Available after configuration"
                    }
                    finalElement
                  />
                </Column>
              </Card>
              <Card
                style={{
                  flex: 1,
                }}
              >
                <Column
                  style={{
                    gap: 24,
                    flex: 1,
                    opacity: 0.5,
                  }}
                >
                  <span
                    style={{
                      flex: 1,
                      height: 4,
                      borderRadius: 2,
                      backgroundColor: designTokens.colors.surface.Mid,
                    }}
                  />
                  <Row gap={16} style={{ alignItems: "center" }}>
                    <IconContainer>
                      <Icon
                        name="file-check-02"
                        color={designTokens.colors.icon.brand}
                        size={24}
                      />
                    </IconContainer>
                    <Text textStyle="20px - Bold" style={{ flex: 1 }}>
                      Administer & Review
                    </Text>
                    <Badge icon="calendar-07">
                      {`${
                        test.data
                          ? new Date(
                              test.data.printDateStart
                            ).toLocaleDateString("en-US", {
                              month: "short",
                              day: "numeric",
                              year: "numeric",
                            })
                          : ""
                      } – ${
                        test.data
                          ? new Date(
                              test.data.administrationDateEnd
                            ).toLocaleDateString("en-US", {
                              month: "short",
                              day: "numeric",
                              year: "numeric",
                            })
                          : ""
                      }`}
                    </Badge>
                  </Row>
                  <Column>
                    <ActionLink
                      title="Print Tests"
                      icon="print 1"
                      to="?groupModal=add-students"
                      disabled
                    />
                    <ActionLink
                      title="Upload Tests"
                      icon="upload-03"
                      disabled
                    />
                    <ActionLink
                      title="Review Test Grading"
                      icon="arrow-right"
                      disabled
                      finalElement
                    />
                  </Column>
                </Column>
              </Card>
            </Row>
          ) : null}
          <Card
            style={{
              flex: 1,
              padding: 24,
              gap: 24,
            }}
          >
            <Row
              gap={16}
              style={{
                alignItems: "center",
              }}
            >
              <IconContainer>
                <Icon
                  name="Students"
                  color={designTokens.colors.icon.brand}
                  size={24}
                />
              </IconContainer>
              <Column style={{ flex: 1 }}>
                <Text textStyle="20px - Bold">Students</Text>
                <Text textStyle="14px - Medium">
                  Add students to this group by uploading a spreadsheet or
                  entering them manually.
                </Text>
              </Column>
              {isDatePast(test.data?.configurationDateEnd) ? null : (
                <Button
                  icon="plus-01"
                  disabled={students.isLoading}
                  text="Add Students"
                  variant="subdued"
                  size="small"
                  onPress={() => {
                    navigate({
                      search: (prev) => ({
                        ...prev,
                        groupModal: "add-students",
                      }),
                    });
                  }}
                />
              )}
            </Row>
            {group?.numberOfStudents ? (
              students.isLoading ? (
                <Loader />
              ) : (
                <table
                  style={{
                    borderCollapse: "collapse",
                  }}
                >
                  <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                          <th
                            key={header.id}
                            style={{
                              padding: 16,
                            }}
                          >
                            {header.isPlaceholder ? null : (
                              <Row gap={6}>
                                <Text
                                  textStyle="subHeading"
                                  style={{
                                    textTransform: "uppercase",
                                    textAlign: "start",
                                  }}
                                >
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                                </Text>
                              </Row>
                            )}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody>
                    {table.getRowModel().rows.map((row) => (
                      <tr key={row.id}>
                        {row.getVisibleCells().map((cell) => (
                          <td
                            key={cell.id}
                            style={{
                              padding: "24px 16px",
                              borderTop: `1px solid ${designTokens.colors.border.subdued}`,
                            }}
                          >
                            <Text textStyle="14px - Medium">
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </Text>
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    {table.getFooterGroups().map((footerGroup) => (
                      <tr key={footerGroup.id}>
                        {footerGroup.headers.map((header) => (
                          <th key={header.id}>
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.footer,
                                  header.getContext()
                                )}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </tfoot>
                </table>
              )
            ) : (
              <Row
                style={{
                  padding: 16,
                  backgroundColor: designTokens.colors.surface.Subdued,
                  borderRadius: 12,
                  justifyContent: "center",
                }}
              >
                <Text textStyle="14px - Semibold">No students added yet.</Text>
              </Row>
            )}
          </Card>
        </Column>
      </Column>
    </PageChrome>
  );
}

function ActionLink({
  title,
  badgeContent,
  icon,
  disabled,
  to,
  finalElement,
  newTab,
}: {
  title: string;
  badgeContent?: string;
  icon: IconName;
  disabled?: boolean;
  to?: string;
  finalElement?: boolean;
  newTab?: boolean;
}) {
  const [ref, hovered] = useHover();
  const designTokens = useDesignTokens();

  return (
    <Link
      ref={ref}
      to={to}
      disabled={disabled}
      rel={newTab ? "noreferrer" : undefined}
      style={{
        padding: "20px 16px",
        borderBottom: finalElement ? "none" : "1px solid #E3E3E3",
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        textDecoration: "none",
        gap: 16,
      }}
    >
      <Row gap={8} style={{ flex: 1, alignItems: "center" }}>
        <Text textStyle="18px - Bold">{title}</Text>
        {badgeContent && (
          <Badge type={disabled ? "info" : "primary"}>{badgeContent}</Badge>
        )}
      </Row>
      <Icon
        name={icon}
        onClick={() => {}}
        disabled={disabled}
        style={{
          backgroundColor:
            hovered && !disabled
              ? designTokens.colors.surface.Disabled
              : "transparent",
        }}
      />
    </Link>
  );
}
