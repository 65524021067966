import { useNavigate, useSearch } from "@tanstack/react-router";
import { useApiQuery } from "../../../../../lib/api";
import {
  Card,
  ChecklistItem,
  Column,
  Icon,
  Loader,
  Row,
  Text,
  useDesignTokens,
} from "@gradience/ui";
import { IconContainer } from "@gradience/ui/dist/components/icon";
import { useCurriculaSelection } from "./curricula";
import { useConceptsSelection } from "./concepts";

export const useChaptersSelection = () => {
  const { selectedChapters, selectedCurricula } = useSearch({
    from: "/$test-slug/groups/$id/configure/",
  });

  // Fetch chapters from selected curricula, because, while a chapter may be
  // selected in the url, the parent curriculum may have been deselected
  const chaptersQuery = useApiQuery(
    "/chapters",
    {},
    {
      enabled:
        selectedChapters !== undefined && selectedCurricula !== undefined,
    },
    { curriculumIds: selectedCurricula }
  );

  return {
    selectedChapters: chaptersQuery.data?.data
      .map((c) => c.id)
      .filter((id) => (selectedChapters ?? []).includes(id)),
    isLoading: chaptersQuery.isLoading,
  };
};

function ConfigureChapters({ step }: { step: number }) {
  const designTokens = useDesignTokens();
  const navigate = useNavigate({ from: "/$test-slug/groups/$id/configure" });

  const curriculaQuery = useApiQuery("/curricula", {});
  const { selectedCurricula } = useCurriculaSelection();
  const { selectedChapters } = useChaptersSelection();
  const { numberOfQuestionsQuery } = useConceptsSelection();
  // First step is curricula selection
  const currentCurriculumId = selectedCurricula![step - 1];
  const currentCurriculum = curriculaQuery.data?.data.find(
    (curriculum) => curriculum.id === currentCurriculumId
  );
  const chaptersQuery = useApiQuery(
    "/chapters",
    {},
    {
      enabled: currentCurriculumId !== undefined,
    },
    {
      // Select all then filter, because this is already fetched by the parent
      curriculumIds: selectedCurricula,
    }
  );
  const chapters = chaptersQuery.data?.data.filter(
    (chapter) => chapter.curriculumId === currentCurriculumId
  );

  const setSelectedChapters = (
    setter: (selectedChapters: string[]) => string[]
  ) => {
    navigate({
      replace: true,
      search: (prev) => ({
        ...prev,
        selectedChapters: setter(
          prev.selectedChapters ?? selectedChapters ?? []
        ),
      }),
    }).then(() => numberOfQuestionsQuery.refetch());
  };

  return chaptersQuery.isLoading ? (
    <Loader />
  ) : (
    <Row style={{ flex: 1 }}>
      <Card
        style={{
          flex: 1,
          padding: 24,
          gap: 24,
        }}
      >
        <Row style={{ gap: 16, alignItems: "center" }}>
          <IconContainer>
            <Icon
              size={24}
              name="list"
              color={designTokens.colors.icon.brand}
            />
          </IconContainer>
          <Column gap={4}>
            <Text textStyle="20px - Bold">Chapter Selection</Text>
            <Text textStyle="14px - Medium">
              Select all chapters that you will have studied by the Exam Date.
            </Text>
          </Column>
        </Row>
        <Text textStyle="18px - Bold" loading={currentCurriculum === undefined}>
          {currentCurriculum?.name}
        </Text>
        <Column>
          {chapters?.map((chapter) => (
            <ChecklistItem
              key={chapter.id}
              label={chapter.name}
              value={(selectedChapters ?? []).includes(chapter.id)}
              onChange={(checked) =>
                setSelectedChapters((prev) => {
                  if (checked) {
                    // If this is the initial selection, also select the
                    // chapters before this one
                    const chaptersFromThisCurriculum = prev.filter((id) =>
                      chapters.some((chapter) => chapter.id === id)
                    );

                    if (chaptersFromThisCurriculum.length === 0) {
                      const indexOfChapter = chapters.findIndex(
                        (c) => c.id === chapter.id
                      );

                      const chaptersBeforeThisOne = chapters.slice(
                        0,
                        indexOfChapter
                      );

                      return [
                        ...prev,
                        ...chaptersBeforeThisOne.map((chapter) => chapter.id),
                        chapter.id,
                      ];
                    }

                    return [...prev, chapter.id];
                  } else {
                    return prev.filter((id) => id !== chapter.id);
                  }
                })
              }
            />
          ))}
        </Column>
      </Card>
    </Row>
  );
}

export default ConfigureChapters;
