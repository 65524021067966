import { useQuery } from "@tanstack/react-query";
import { useApiQuery } from "../lib/api";
import { Test } from "@gradience/api-types";
import { useParams } from "@tanstack/react-router";
import { useMemo } from "react";

export const useLatestTest = () => {
  const tests = useApiQuery("/tests", {});

  const latestTestId = useMemo(
    () =>
      tests.data?.data.reduce((a, b) =>
        new Date(a.reservationDateStart) > new Date(b.reservationDateStart)
          ? a
          : b
      ).id,
    [tests.data?.data]
  );

  return useQuery<Test | null>({
    queryKey: ["latest-test", latestTestId],
    queryFn: async () => {
      return tests.data?.data.find((test) => test.id === latestTestId) ?? null;
    },
  });
};

export const useSelectedTest = () => {
  const params = useParams({
    strict: false,
  });

  const tests = useApiQuery("/tests", {});

  const selectedTestSlug = useMemo(() => {
    const testSlugParamIsValid = tests.data?.data.some(
      (test) => test.yearIdentifier === params["test-slug"]
    );

    return testSlugParamIsValid
      ? params["test-slug"]
      : tests.data?.data.reduce((a, b) =>
          new Date(a.reservationDateStart) > new Date(b.reservationDateStart)
            ? a
            : b
        ).yearIdentifier;
  }, [params, tests.data?.data]);

  return useQuery<Test | null>({
    queryKey: ["selected-test", selectedTestSlug],
    queryFn: async () => {
      return (
        tests.data?.data.find(
          (test) => test.yearIdentifier === selectedTestSlug
        ) ?? null
      );
    },
    enabled: selectedTestSlug !== undefined && tests.data !== undefined,
  });
};
