import {
  Button,
  Column,
  Input,
  Loader,
  Modal,
  ModalButtonsRow,
  RadioInput,
  Row,
  Text,
  useDesignTokens,
} from "@gradience/ui";
import { useForm } from "@tanstack/react-form";
import { isApiError, useApiPost, useApiPut, useApiQuery } from "../../lib/api";
import { useQueryClient } from "@tanstack/react-query";
import toast from "../../domain/toast";
import { InputOptions, InputType } from "@gradience/ui/dist/components/input";
import { ComponentProps, CSSProperties, useMemo, useState } from "react";
import { useLoggedInUser } from "../../lib/auth";

const EditUserModal = ({
  isOpen,
  userId,
  onClose,
  loading: _loading,
}: {
  isOpen: boolean;
  onClose: () => unknown;
  userId: string | null;
  loading?: boolean;
}) => {
  const createUserMutation = useApiPost("/users");
  const updateUserMutation = useApiPut("/users/:id", { id: userId ?? "" });
  const mutation = userId ? updateUserMutation : createUserMutation;
  const user = useApiQuery(
    "/users/:id",
    { id: userId ?? "" },
    { enabled: userId !== null }
  );
  const loading = (user.isLoading && userId) || _loading;
  const loggedInUser = useLoggedInUser();
  const editingSelf = userId === loggedInUser.data?.id;

  const queryClient = useQueryClient();
  const [error, setError] = useState<string>();

  const form = useForm({
    defaultValues: useMemo(
      () => ({
        firstName: user.data?.firstName ?? "",
        lastName: user.data?.lastName ?? "",
        email: user.data?.email ?? "",
        roleAtSchool: user.data?.roleAtSchool ?? "",
        roles: user.data?.roles ?? [],
      }),
      [
        user.data?.email,
        user.data?.firstName,
        user.data?.lastName,
        user.data?.roleAtSchool,
        user.data?.roles,
      ]
    ),
    onSubmit: async ({ value }) => {
      try {
        await mutation.mutateAsync({
          ...value,
        });
        form.reset();
        queryClient.invalidateQueries({ queryKey: ["users"] });
        toast({
          message: `User ${userId ? "updated" : "created"} successfully`,
          icon: "user",
        });
        onClose();
      } catch (e) {
        if (isApiError(e)) {
          setError(e.response?.data.errors[0].message);
        } else {
          throw e;
        }
      }
    },
  });

  const FormField = ({
    name,
    label,
    placeholder,
    required,
    style,
    options,
    type,
    errorText,
    autocomplete,
    helpText,
    autofocus,
    disabled,
  }: {
    name: ComponentProps<(typeof form)["Field"]>["name"];
    label?: string;
    placeholder?: string;
    required?: boolean;
    style?: CSSProperties;
    options?: InputOptions;
    type?: InputType;
    errorText?: string;
    autocomplete?: string;
    helpText?: string;
    autofocus?: boolean;
    disabled?: boolean;
  }) => {
    return (
      <form.Field
        name={name}
        children={({ name, state, handleBlur, handleChange }) => {
          return (
            <Input
              helpText={helpText}
              style={{ flex: 1, ...style }}
              placeholder={placeholder}
              required={required}
              autocomplete={autocomplete}
              // @ts-ignore
              name={name}
              options={options}
              type={type}
              disabled={disabled}
              inputProps={{
                autoFocus: autofocus,
                value: state.value as string,
                onBlur: handleBlur,
                onChange: (e) => {
                  // This will work as long as we use all string values until I
                  // can fix this
                  // @ts-ignore
                  handleChange(e.target.value);
                },
              }}
              errorText={
                state.meta.errors.length
                  ? state.meta.errors.join(", ")
                  : errorText
              }
            />
          );
        }}
      />
    );
  };

  const designTokens = useDesignTokens();

  return (
    <Modal
      open={isOpen}
      close={() => {
        form.reset();
        onClose();
      }}
    >
      {loading ? (
        <Loader />
      ) : (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            form.handleSubmit();
          }}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 32,
            width: "100%",
          }}
        >
          <Text textStyle="24px - Bold">Add Users to your School</Text>
          {error && (
            <Text
              textStyle="14px - Semibold"
              style={{ color: designTokens.colors.misc.Brand }}
            >
              {error}
            </Text>
          )}
          <Column gap={8}>
            <Text textStyle="16px - Semibold">User Details</Text>
            <Row gap={8} style={{ flex: 1 }}>
              <FormField name="firstName" placeholder="First Name" required />
              <FormField name="lastName" placeholder="Last Name" required />
            </Row>
            <FormField
              name="email"
              placeholder="Email"
              type="email"
              required
              disabled={userId !== null}
            />
            <FormField
              name="roleAtSchool"
              placeholder="Title (i.e. Head Latin Teacher)"
            />
          </Column>
          <Column gap={8}>
            <Text textStyle="16px - Semibold">Permissions</Text>
            <Row gap={8}>
              <form.Field
                name="roles"
                children={({ state, setValue }) => (
                  <RadioInput
                    disabled={editingSelf}
                    name="roles"
                    value={state.value.includes("ADMIN") ? "full" : "partial"}
                    setValue={(access) =>
                      setValue(access === "full" ? ["ADMIN"] : [])
                    }
                    options={[
                      {
                        label: "Partial — Can create and edit groups.",
                        value: "partial",
                      },
                      {
                        label:
                          "Full — Can create, edit, and delete groups, and add users.",
                        value: "full",
                      },
                    ]}
                  />
                )}
              />
            </Row>
          </Column>
          <ModalButtonsRow>
            <Button
              text={userId ? "Update User" : "Add User"}
              type="submit"
              variant="primary"
              loading={form.state.isSubmitting}
            />
            <Button
              loading={form.state.isSubmitting}
              linkProps={{
                search: (prev) => ({ ...prev, modal: undefined }),
              }}
              text="Cancel"
            />
          </ModalButtonsRow>
        </form>
      )}
    </Modal>
  );
};

export default EditUserModal;
