import { Button, Column, Input, Loader, Modal, Row, Text } from "@gradience/ui";
import { useNavigate, useSearch } from "@tanstack/react-router";
import useSchool from "../../../lib/use-school";
import { queryKeys, useApiPut } from "../../../lib/api";
import { useQueryClient } from "@tanstack/react-query";
import { useForm } from "@tanstack/react-form";

const SchoolInfoModal = () => {
  const search = useSearch({ from: "/$test-slug/my-school/" });
  const navigate = useNavigate({ from: "/" });
  const school = useSchool();

  const putSchool = useApiPut("/school");

  const queryClient = useQueryClient();

  const onClose = () =>
    navigate({ search: (prev) => ({ ...prev, modal: undefined }) });
  const isOpen = search.modal === "school-info";

  const submitting = putSchool.isPending;

  const form = useForm({
    defaultValues: {
      name: school.data?.name,
      address: {
        line1: school.data?.address?.line1,
        line2: school.data?.address?.line2,
        city: school.data?.address?.city,
        state: school.data?.address?.state,
        zip: school.data?.address?.zip,
      },
      size: school.data?.size,
    },
    onSubmit: async ({ value }) => {
      await putSchool.mutateAsync({
        name: value.name,
        address: {
          line1: value.address.line1,
          line2: value.address.line2,
          city: value.address.city,
          state: value.address.state,
          zip: value.address.zip,
        },
        size: value.size,
      });

      queryClient.invalidateQueries({ queryKey: queryKeys["/school"] });

      onClose();
    },
  });

  return (
    <Modal open={isOpen} close={onClose}>
      {school.isLoading ? (
        <Loader />
      ) : (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            form.handleSubmit();
          }}
          style={{ flex: 1, gap: 32, display: "flex", flexDirection: "column" }}
        >
          <Text textStyle="24px - Bold">Update School Information</Text>
          <Column gap={32} style={{ flex: 1 }}>
            <form.Field
              name="name"
              children={(field) => (
                <label
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 8,
                  }}
                >
                  <Text textStyle="16px - Semibold">School Name*</Text>
                  <Input
                    name={field.name}
                    inputProps={{
                      onBlur: field.handleBlur,
                    }}
                    value={field.state.value}
                    setValue={(value) => field.handleChange(value)}
                  />
                </label>
              )}
            />
            <Column gap={8}>
              <form.Field
                name="address.line1"
                children={(field) => (
                  <label
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 8,
                    }}
                  >
                    <Text textStyle="16px - Semibold">School Address*</Text>
                    <Input
                      placeholder="Street Address"
                      name={field.name}
                      inputProps={{
                        onBlur: field.handleBlur,
                      }}
                      value={field.state.value}
                      setValue={(value) => field.handleChange(value)}
                    />
                  </label>
                )}
              />
              <form.Field
                name="address.line2"
                children={(field) => (
                  <Input
                    placeholder="Address Line 2"
                    name={field.name}
                    inputProps={{
                      onBlur: field.handleBlur,
                    }}
                    value={field.state.value}
                    setValue={(value) => field.handleChange(value)}
                  />
                )}
              />
              <Row gap={8}>
                <form.Field
                  name="address.city"
                  children={(field) => (
                    <Input
                      placeholder="City"
                      name={field.name}
                      inputProps={{
                        onBlur: field.handleBlur,
                      }}
                      value={field.state.value}
                      setValue={(value) => field.handleChange(value)}
                    />
                  )}
                />
                <form.Field
                  name="address.state"
                  children={(field) => (
                    <Input
                      placeholder="State"
                      name={field.name}
                      inputProps={{
                        onBlur: field.handleBlur,
                      }}
                      value={field.state.value}
                      setValue={(value) => field.handleChange(value)}
                    />
                  )}
                />
                <form.Field
                  name="address.zip"
                  children={(field) => (
                    <Input
                      placeholder="Zip"
                      name={field.name}
                      inputProps={{
                        onBlur: field.handleBlur,
                      }}
                      value={field.state.value}
                      setValue={(value) => field.handleChange(value)}
                    />
                  )}
                />
              </Row>
            </Column>
            <form.Field
              name="size"
              children={(field) => (
                <Input
                  name={field.name}
                  inputProps={{
                    onBlur: field.handleBlur,
                  }}
                  type="select"
                  options={[
                    { value: "1-50", label: "1-50" },
                    { value: "51-100", label: "51-100" },
                    { value: "101-200", label: "101-200" },
                    { value: "201-500", label: "201-500" },
                    { value: "500+", label: "500+" },
                  ]}
                  value={field.state.value}
                  setValue={(value) => field.handleChange(value)}
                />
              )}
            />
          </Column>
          <Row gap={16}>
            <Button
              type="submit"
              loading={submitting}
              text="Save"
              variant="primary"
              size="large"
            />
            <Button
              type="button"
              text="Cancel"
              disabled={submitting}
              linkProps={{
                search: (prev) => ({ ...prev, modal: undefined }),
              }}
              size="large"
            />
          </Row>
        </form>
      )}
    </Modal>
  );
};

export default SchoolInfoModal;
