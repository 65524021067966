var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { usePopper } from "react-popper";
import { OptionContainer } from "./dropdown-menu";
import Text from "./text";
import Input from "./input";
import { Column } from "./layout";
import { useLocalSearchIndex } from "../lib/local-search-index";
import { useDesignTokens } from "../lib/design-tokens";
/**
 * Shows a list of suggestions and allows the user to select one. The input has
 * a value and a query value. The value is used internally while the query value
 * is used to fetch suggestions and is displayed to the user.
 *
 * Must take care of debouncing the refetch mechanism.
 *
 * If suggestions are not sorted or filtered, the `indexLocally` prop can be set
 * to true to use the a local search index to search the suggestions.
 *
 * Value label is used to display the value in the field if it isn't one of the
 * suggestions.
 */
var SuggestionInput = function (_a) {
    var inputProps = _a.inputProps, suggestions = _a.suggestions, queryValue = _a.queryValue, value = _a.value, valueLabel = _a.valueLabel, setQueryValue = _a.setQueryValue, setValue = _a.setValue, onSelection = _a.onSelection, tooltipUpdaterRef = _a.tooltipUpdaterRef, _b = _a.tooltipEnabled, tooltipEnabled = _b === void 0 ? true : _b, mustPickFromSuggestions = _a.mustPickFromSuggestions, indexLocally = _a.indexLocally, leadingIcon = _a.leadingIcon, dark = _a.dark, placeholder = _a.placeholder, renderOption = _a.renderOption, _c = _a.dropdownVariant, dropdownVariant = _c === void 0 ? "default" : _c, loadingSuggestions = _a.loadingSuggestions, noResultsComponent = _a.noResultsComponent, _d = _a.persistValue, persistValue = _d === void 0 ? true : _d;
    var searchResults = useLocalSearchIndex({
        items: suggestions,
        indexFields: ["label"],
        query: queryValue,
        enabled: indexLocally,
    });
    var orderedSuggestions = searchResults !== null && searchResults !== void 0 ? searchResults : suggestions;
    var valueRef = useRef(value);
    var _e = useState(false), isOpen = _e[0], setIsOpen = _e[1];
    var _f = useState(null), referenceElement = _f[0], setReferenceElement = _f[1];
    var _g = useState(null), popperElement = _g[0], setPopperElement = _g[1];
    var _h = usePopper(referenceElement, popperElement, {
        placement: "bottom-start",
        modifiers: [
            {
                name: "offset",
                options: {
                    offset: [0, 6],
                },
            },
        ],
    }), styles = _h.styles, attributes = _h.attributes, update = _h.update;
    useEffect(function () {
        if (tooltipUpdaterRef) {
            tooltipUpdaterRef.current = update;
        }
    }, [tooltipUpdaterRef, update]);
    var _j = useState(0), highlightedIndex = _j[0], setHighlightedIndex = _j[1];
    var optionRefs = useRef([]);
    var Option = function (_a) {
        var option = _a.option, index = _a.index, onMouseDown = _a.onMouseDown, highlighted = _a.highlighted, optionRefs = _a.optionRefs;
        return (_jsxs(OptionContainer, { tabIndex: -1, className: "dropdown-option", onMouseDown: onMouseDown, ref: function (el) {
                if (el)
                    optionRefs.current[index] = el;
            }, "$active": highlighted, children: [_jsx(Text, { style: {
                        fontSize: 14,
                        lineHeight: "20px",
                        textAlign: "start",
                        flex: 1,
                    }, children: option.label }), option.caption && (_jsx(Text, { textStyle: "caption", style: {
                        color: designTokens.colors.text.disabled,
                    }, children: option.caption }))] }));
    };
    var handleKeyDown = function (e) {
        if (!orderedSuggestions)
            return;
        switch (e.key) {
            case "ArrowDown":
                setHighlightedIndex(function (prevIndex) {
                    return prevIndex === orderedSuggestions.length - 1 ? 0 : prevIndex + 1;
                });
                break;
            case "ArrowUp":
                setHighlightedIndex(function (prevIndex) {
                    return prevIndex <= 0 ? orderedSuggestions.length - 1 : prevIndex - 1;
                });
                break;
            case "Enter":
                if (highlightedIndex >= 0) {
                    setValue(orderedSuggestions[highlightedIndex].value);
                    valueRef.current = orderedSuggestions[highlightedIndex].value;
                    setQueryValue(persistValue ? orderedSuggestions[highlightedIndex].label : "");
                    persistValue && setIsOpen(false);
                    onSelection === null || onSelection === void 0 ? void 0 : onSelection(orderedSuggestions[highlightedIndex].value, e);
                    setHighlightedIndex(-1);
                    e.preventDefault();
                    e.stopPropagation();
                }
                break;
            case "Escape":
                if (isOpen) {
                    setIsOpen(false);
                    e.preventDefault();
                    e.stopPropagation();
                }
                break;
            default:
                break;
        }
    };
    useEffect(function () {
        if (highlightedIndex >= 0 && optionRefs.current[highlightedIndex]) {
            optionRefs.current[highlightedIndex].scrollIntoView({
                behavior: "smooth",
                block: "nearest",
            });
        }
    }, [highlightedIndex]);
    useEffect(function () {
        if (orderedSuggestions) {
            setHighlightedIndex(0);
        }
    }, [orderedSuggestions]);
    var designTokens = useDesignTokens();
    return (_jsxs("span", { style: {
            position: "relative",
            display: "flex",
        }, ref: setReferenceElement, children: [_jsx(Input, { leadingIcon: leadingIcon, placeholder: placeholder, value: queryValue, style: {
                    flex: 1,
                    position: "relative",
                    zIndex: isOpen && tooltipEnabled ? 2 : 0,
                }, inputProps: __assign(__assign({}, inputProps), { onKeyDownCapture: function (e) {
                        // Turn off scrolling when tab pressed. I searched extensively
                        // through popper.js and can't figure out why, but popper focuses
                        // the popper div when tabbing if the div is scrollable, when the
                        // content overflows the container. This prevents the next input
                        // from being properly focused. The element will be rerendered if
                        // the dropdown is opened again, so it's fine to turn off scrolling
                        // here.
                        if (e.key === "Tab") {
                            var scrollingOptionsContainer = document.querySelector(".scrolling-options-container");
                            if (scrollingOptionsContainer) {
                                scrollingOptionsContainer.style.overflowY =
                                    "hidden";
                            }
                        }
                    }, onFocus: function (e) {
                        var _a;
                        setIsOpen(true);
                        (_a = inputProps === null || inputProps === void 0 ? void 0 : inputProps.onFocus) === null || _a === void 0 ? void 0 : _a.call(inputProps, e);
                    }, onBlur: function (e) {
                        var _a, _b, _c;
                        if (mustPickFromSuggestions === true) {
                            var selectedSuggestion = orderedSuggestions.find(function (suggestion) { return suggestion.value === valueRef.current; });
                            setQueryValue(persistValue
                                ? ((_b = (_a = selectedSuggestion === null || selectedSuggestion === void 0 ? void 0 : selectedSuggestion.label) !== null && _a !== void 0 ? _a : valueLabel) !== null && _b !== void 0 ? _b : "")
                                : "");
                        }
                        setIsOpen(false);
                        (_c = inputProps === null || inputProps === void 0 ? void 0 : inputProps.onBlur) === null || _c === void 0 ? void 0 : _c.call(inputProps, e);
                    }, autoComplete: "off", onChange: function (e) {
                        var _a;
                        setValue(null);
                        setQueryValue(e.target.value);
                        valueRef.current = null;
                        setIsOpen(true); // Open dropdown on input change
                        (_a = inputProps === null || inputProps === void 0 ? void 0 : inputProps.onChange) === null || _a === void 0 ? void 0 : _a.call(inputProps, e);
                    }, onKeyDown: handleKeyDown }) }), isOpen && orderedSuggestions && tooltipEnabled && (_jsxs("div", __assign({ ref: setPopperElement, style: __assign({ maxHeight: 286, padding: 4, boxSizing: "border-box", flex: 1, width: dropdownVariant === "surround" ? "calc(100% + 16px)" : "100%", position: "relative", zIndex: 1, display: "flex" }, styles.popper) }, attributes.popper, { children: [_jsx("span", { style: {
                            position: "absolute",
                            top: dropdownVariant === "surround" ? -52 : 0,
                            borderRadius: 12,
                            left: dropdownVariant === "surround" ? -8 : 0,
                            right: dropdownVariant === "surround" ? 8 : 0,
                            bottom: 8,
                            border: "1px solid ".concat(designTokens.colors.border.default),
                            boxShadow: "0px 6px 20px 0px rgba(0, 0, 0, 0.12), 0px 0px 2px 0px rgba(0, 0, 0, 0.16)",
                            backgroundColor: designTokens.colors.surface.White,
                            zIndex: 1,
                        } }), _jsxs(Column, { className: "scrolling-options-container", style: {
                            boxSizing: "border-box",
                            overflowY: "auto",
                            position: "relative",
                            left: dropdownVariant === "surround" ? -8 : 0,
                            zIndex: 2,
                            paddingBottom: 8,
                            flex: 1,
                        }, children: [orderedSuggestions.length === 0 &&
                                !loadingSuggestions &&
                                (noResultsComponent ? (noResultsComponent) : (_jsx(Text, { textStyle: "14px - Medium", style: {
                                        color: designTokens.colors.text.default,
                                        padding: 8,
                                    }, children: "No results" }))), orderedSuggestions.map(function (option, index) {
                                var OptionComponent = renderOption !== null && renderOption !== void 0 ? renderOption : Option;
                                return (_jsx(OptionComponent, { highlighted: highlightedIndex === index, option: option, index: index, optionRefs: optionRefs, onMouseDown: function (e) {
                                        setValue(option.value);
                                        valueRef.current = option.value;
                                        setQueryValue(persistValue ? option.label : "");
                                        persistValue && setIsOpen(false);
                                        onSelection === null || onSelection === void 0 ? void 0 : onSelection(option.value, e);
                                        e.preventDefault();
                                    } }, option.key));
                            })] })] })))] }));
};
export default SuggestionInput;
