var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { usePopper } from "react-popper";
import { useDesignTokens } from "../lib/design-tokens";
import styled from "styled-components";
import Text from "./text";
import useClickAway from "../lib/use-click-away";
import Checkbox from "./checkbox";
import Icon from "./icon";
var DropdownMenu = function (_a) {
    var options = _a.options, onSelect = _a.onSelect, referenceElement = _a.referenceElement, setClosed = _a.setClosed, checkboxes = _a.checkboxes;
    var _b = useState(null), popperElement = _b[0], setPopperElement = _b[1];
    var _c = usePopper(referenceElement, popperElement, {
        placement: "bottom-start",
        modifiers: [
            {
                name: "offset",
                options: {
                    offset: [0, 6],
                },
            },
        ],
    }), styles = _c.styles, attributes = _c.attributes;
    var designTokens = useDesignTokens();
    var _d = useState(0), activeIndex = _d[0], setActiveIndex = _d[1];
    useKeyDown(function () {
        if (activeIndex === null) {
            setActiveIndex(options.length - 1);
        }
        else {
            setActiveIndex((activeIndex - 1) % options.length);
        }
    }, ["ArrowUp"]);
    useKeyDown(function () {
        if (activeIndex === null) {
            setActiveIndex(0);
        }
        else {
            setActiveIndex((activeIndex + 1) % options.length);
        }
    }, ["ArrowDown"]);
    useKeyDown(function () {
        if (activeIndex !== null) {
            onSelect === null || onSelect === void 0 ? void 0 : onSelect(options[activeIndex].value);
            setClosed();
        }
    }, ["Enter"]);
    useClickAway(referenceElement, function (event) {
        setClosed();
        event.stopPropagation();
    });
    return (_jsx("div", __assign({ ref: setPopperElement, style: __assign({ backgroundColor: designTokens.colors.surface.White, borderRadius: 12, width: 192, padding: 4, boxShadow: "0px 6px 20px 0px rgba(0, 0, 0, 0.12), 0px 0px 2px 0px rgba(0, 0, 0, 0.16)" }, styles.popper) }, attributes.popper, { children: options.map(function (option, index) { return (_jsxs(OptionContainer, { color: designTokens.colors.icon.default, "$active": activeIndex === index, 
            // Mousedown is used instead of click to prevent the dropdown from
            // closing when the user clicks on an option due to the input blurring
            onMouseDown: function (event) {
                event.preventDefault();
                event.stopPropagation();
                onSelect === null || onSelect === void 0 ? void 0 : onSelect(option.value);
                if (!checkboxes) {
                    setClosed();
                }
            }, children: [checkboxes ? _jsx(Checkbox, { value: Boolean(option.checked) }) : null, option.icon ? _jsx(Icon, { name: option.icon }) : null, _jsx(Text, { children: option.label })] }, option.value)); }) })));
};
export var OptionContainer = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  appearance: none;\n  border: none;\n  background-color: transparent;\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  gap: 8px;\n  align-items: center;\n  padding: 16px 16px;\n  border-radius: 12px;\n  cursor: ", ";\n  z-index: 1;\n\n  ", "\n\n  &:hover {\n    ", ";\n  }\n"], ["\n  appearance: none;\n  border: none;\n  background-color: transparent;\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  gap: 8px;\n  align-items: center;\n  padding: 16px 16px;\n  border-radius: 12px;\n  cursor: ", ";\n  z-index: 1;\n\n  ", "\n\n  &:hover {\n    ", ";\n  }\n"])), function (_a) {
    var disabled = _a.disabled;
    return (disabled ? "not-allowed" : "pointer");
}, function (_a) {
    var $active = _a.$active, theme = _a.theme, disabled = _a.disabled;
    return $active && !disabled
        ? "\n    background-color: ".concat(theme.colors.surface.Disabled, ";\n  ")
        : null;
}, function (_a) {
    var disabled = _a.disabled, theme = _a.theme;
    return disabled ? "" : "background-color: ".concat(theme.colors.surface.Disabled);
});
export var useKeyDown = function (callback, keys) {
    var onKeyDown = useCallback(function (event) {
        var wasAnyKeyPressed = keys.some(function (key) { return event.key === key; });
        if (wasAnyKeyPressed) {
            event.preventDefault();
            callback();
        }
    }, [callback, keys]);
    useEffect(function () {
        document.addEventListener("keydown", onKeyDown);
        return function () {
            document.removeEventListener("keydown", onKeyDown);
        };
    }, [onKeyDown]);
};
export default DropdownMenu;
var templateObject_1;
