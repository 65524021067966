import { useNavigate, useParams, useSearch } from "@tanstack/react-router";
import { useApiQuery } from "../../../../../lib/api";
import {
  Card,
  ChecklistItem,
  Column,
  Icon,
  Row,
  Text,
  useDesignTokens,
} from "@gradience/ui";
import { IconContainer } from "@gradience/ui/dist/components/icon";
import { useConceptsSelection } from "./concepts";
import { useSelectedTest } from "../../../../../domain/use-latest-test";

export const useCurriculaSelection = () => {
  const { selectedCurricula } = useSearch({
    from: "/$test-slug/groups/$id/configure/",
  });

  return { selectedCurricula };
};

function ConfigureCurricula() {
  const designTokens = useDesignTokens();
  const navigate = useNavigate({ from: "/$test-slug/groups/$id/configure" });
  const testQuery = useSelectedTest();

  const curriculaQuery = useApiQuery(
    "/curricula",
    {},
    {
      enabled: testQuery.isSuccess,
    },
    {
      testId: testQuery.data?.id,
    }
  );
  const { selectedCurricula } = useCurriculaSelection();
  const { numberOfQuestionsQuery } = useConceptsSelection();
  const setSelectedCurricula = (
    setter: (selectedCurricula: string[]) => string[]
  ) => {
    navigate({
      replace: true,
      search: (prev) => ({
        ...prev,
        selectedCurricula: setter(
          prev.selectedCurricula ?? selectedCurricula ?? []
        ),
      }),
    }).then(() => numberOfQuestionsQuery.refetch());
  };

  return (
    <Row style={{ flex: 1 }}>
      <Card
        style={{
          flex: 1,
          padding: 24,
          gap: 24,
        }}
      >
        <Row style={{ gap: 16, alignItems: "center" }}>
          <IconContainer>
            <Icon
              size={24}
              name="list"
              color={designTokens.colors.icon.brand}
            />
          </IconContainer>
          <Column gap={4}>
            <Text textStyle="20px - Bold">Curriculum Selection</Text>
            <Text textStyle="14px - Medium">
              Which of the following curricula did you use this year? Select all
              that apply.
            </Text>
          </Column>
        </Row>
        <Column>
          {curriculaQuery.data?.data.map((curriculum) => (
            <ChecklistItem
              key={curriculum.id}
              label={curriculum.name}
              value={(selectedCurricula ?? []).includes(curriculum.id)}
              onChange={(checked) =>
                setSelectedCurricula((prev) =>
                  checked
                    ? [...prev, curriculum.id]
                    : prev.filter((id) => id !== curriculum.id)
                )
              }
            />
          ))}
        </Column>
      </Card>
    </Row>
  );
}

export default ConfigureCurricula;
