import { useNavigate, useParams, useSearch } from "@tanstack/react-router";
import { postApi, queryKeys, useApiQuery } from "../../../../../lib/api";
import {
  Accordion,
  Card,
  ChecklistItem,
  Column,
  Icon,
  Row,
  SuggestionInput,
  Text,
  useDesignTokens,
} from "@gradience/ui";
import { IconContainer } from "@gradience/ui/dist/components/icon";
import { useCallback, useMemo, useState } from "react";
import toast from "../../../../../domain/toast";
import { useCurriculaSelection } from "./curricula";
import { useChaptersSelection } from "./chapters";
import { useQuery } from "@tanstack/react-query";
import { useSelectedTest } from "../../../../../domain/use-latest-test";

export const useConceptsSelection = () => {
  const testQuery = useSelectedTest();
  const { selectedChapters, isLoading } = useChaptersSelection();
  const { selectedCurricula } = useCurriculaSelection();
  const enabled = selectedChapters && selectedChapters.length > 0;
  const chapters = useApiQuery(
    "/chapters",
    {},
    { enabled },
    { curriculumIds: selectedCurricula }
  );

  const expectedSelectedConcepts = useMemo(() => {
    if (!enabled) {
      return [];
    }

    return (
      chapters.data?.data
        .filter((chapter) => selectedChapters.includes(chapter.id))
        .flatMap((chapter) => chapter.conceptIds) ?? []
    );
  }, [chapters.data?.data, enabled, selectedChapters]);

  const { selectedConcepts, deselectedConcepts } = useSearch({
    from: "/$test-slug/groups/$id/configure/",
  });

  const finalSelectedConcepts = useMemo(() => {
    // Add selectedConcepts to the expectedSelectedConcepts, but filter out
    // deselectedConcepts
    return (
      expectedSelectedConcepts
        .filter((conceptId) => !deselectedConcepts.includes(conceptId))
        .concat(selectedConcepts ?? [])
        // unique
        .filter((value, index, self) => self.indexOf(value) === index)
    );
  }, [expectedSelectedConcepts, deselectedConcepts, selectedConcepts]);

  const numberOfQuestionsQuery = useQuery({
    enabled: testQuery.isSuccess,
    queryKey: queryKeys["/number-of-questions"],
    queryFn: () =>
      // This is really a query, we just use a post request because the query
      // can be very long.
      postApi("/number-of-questions", {
        conceptIds: finalSelectedConcepts ?? [],
        testId: testQuery.data?.id!,
      }),
  });

  const navigate = useNavigate({ from: "/$test-slug/groups/$id/configure" });
  const deselectConcept = useCallback(
    (id: string) => {
      navigate({
        search: (prev) => ({
          ...prev,
          deselectedConcepts: [...prev.deselectedConcepts, id],
          selectedConcepts: (
            prev.selectedConcepts ?? finalSelectedConcepts
          ).filter((_id) => _id !== id),
        }),
        replace: true,
      }).then(() => numberOfQuestionsQuery.refetch());
    },
    [finalSelectedConcepts, navigate, numberOfQuestionsQuery]
  );

  const selectConcept = useCallback(
    (id: string) => {
      navigate({
        search: (prev) => ({
          ...prev,
          selectedConcepts: [
            ...(prev.selectedConcepts || finalSelectedConcepts),
            id,
          ],
          deselectedConcepts: prev.deselectedConcepts.filter(
            (_id) => _id !== id
          ),
        }),
        replace: true,
      }).then(() => numberOfQuestionsQuery.refetch());
    },
    [navigate, numberOfQuestionsQuery, finalSelectedConcepts]
  );

  return {
    selectedConcepts: finalSelectedConcepts,
    isLoading,
    deselectConcept,
    selectConcept,
    numberOfQuestionsQuery,
  };
};

function ConfigureConcepts() {
  const designTokens = useDesignTokens();
  const testQuery = useSelectedTest();

  const conceptGroupsQuery = useApiQuery(
    "/concept-groups",
    {},
    {
      enabled: testQuery.isSuccess,
    },
    {
      testId: testQuery.data?.id!,
    }
  );

  const { selectConcept, deselectConcept, selectedConcepts } =
    useConceptsSelection();
  const [queryValue, setQueryValue] = useState("");

  const suggestions = useMemo(() => {
    return (
      conceptGroupsQuery.data?.data.flatMap((group) =>
        group.concepts
          .filter((concept) => !selectedConcepts.includes(concept.id))
          .map((concept) => ({
            label: concept.name,
            caption: group.name,
            value: concept,
            key: concept.id,
          }))
      ) ?? []
    );
  }, [conceptGroupsQuery.data?.data, selectedConcepts]);

  return (
    <Row style={{ flex: 1 }}>
      <Card
        style={{
          flex: 1,
          padding: 24,
          gap: 24,
        }}
      >
        <Row style={{ gap: 16, alignItems: "center" }}>
          <IconContainer>
            <Icon
              size={24}
              name="list"
              color={designTokens.colors.icon.brand}
            />
          </IconContainer>
          <Column gap={4}>
            <Text textStyle="20px - Bold">Review Concepts</Text>
            <Text textStyle="14px - Medium">
              Add all concepts your class has studied this year to the tested
              concepts list and carefully review before finalizing.
            </Text>
          </Column>
        </Row>

        <SuggestionInput
          queryValue={queryValue}
          persistValue={false}
          setQueryValue={setQueryValue}
          value={undefined}
          setValue={(value) => {
            if (value) {
              selectConcept(value.id);
              toast({
                message: `"${value.name}" was added`,
                icon: "list",
                action: {
                  label: "Undo",
                  onClick: () => deselectConcept(value.id),
                },
              });
            }
          }}
          placeholder="Search to add"
          leadingIcon="search-01"
          suggestions={suggestions}
        />

        <Text
          textStyle="14px - Medium"
          style={{
            color: designTokens.colors.text.disabled,
          }}
        >
          Or explore by category
        </Text>

        <Column gap={8}>
          {conceptGroupsQuery.data?.data.map((conceptGroup) => (
            <Accordion key={conceptGroup.id} title={conceptGroup.name}>
              {conceptGroup.concepts.map((concept) => (
                <ChecklistItem
                  key={concept.id}
                  label={concept.name}
                  value={selectedConcepts.includes(concept.id)}
                  onChange={(checked) => {
                    if (checked) {
                      selectConcept(concept.id);
                    } else {
                      deselectConcept(concept.id);
                    }
                  }}
                />
              ))}
            </Accordion>
          ))}
        </Column>
      </Card>
    </Row>
  );
}

export default ConfigureConcepts;
