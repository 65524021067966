import Fuse from "fuse.js";
import { useMemo } from "react";
/**
 * A hook to search items using a local search index. It returns the items that
 * match the query. Items should be a stable reference, as the search index is
 * built whenever the items change.
 *
 * When disabled, the search index is not built, and the search results are
 * null.
 */
var useLocalSearchIndex = function (_a) {
    var items = _a.items, indexFields = _a.indexFields, query = _a.query, _b = _a.searchWhenEmpty, searchWhenEmpty = _b === void 0 ? false : _b, _c = _a.enabled, enabled = _c === void 0 ? true : _c;
    var stableIndexFields = useMemo(function () { return indexFields; }, [indexFields]);
    var searchIndex = useMemo(function () {
        if (!enabled) {
            return null;
        }
        return new Fuse(items, {
            keys: stableIndexFields,
            includeScore: true,
            threshold: 0.3,
        });
    }, [enabled, stableIndexFields, items]);
    var searchResults = useMemo(function () {
        if (!searchIndex) {
            return null;
        }
        if (searchWhenEmpty || query.length > 0) {
            return searchIndex.search(query).map(function (result) { return result.item; });
        }
        return null;
    }, [query, searchIndex, searchWhenEmpty]);
    return searchResults;
};
export { useLocalSearchIndex };
