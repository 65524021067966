import { Button, Text, Row } from "@gradience/ui";
import {
  createFileRoute,
  useNavigate,
  useSearch,
} from "@tanstack/react-router";
import { useLoggedInUser, useLogout, useVerifyEmail } from "../lib/auth";
import { useEffect } from "react";
import AuthChrome from "../components/auth-chrome";
import { useApiPost } from "../lib/api";

type VerifyEmailSearch = {
  token?: string | undefined;
};

export const Route = createFileRoute("/verify-email")({
  component: VerifyEmail,
  validateSearch: (search): VerifyEmailSearch => {
    if (typeof search.token !== "string") {
      return { token: undefined };
    } else {
      return { token: search.token };
    }
  },
});

function VerifyEmail() {
  const search = useSearch({
    from: "/verify-email",
  });
  const navigate = useNavigate();
  const loggedInUser = useLoggedInUser();

  const verifyEmail = useVerifyEmail();
  const requestVerificationEmail = useApiPost("/auth/verify-email-request");

  const logout = useLogout();

  useEffect(() => {
    if (search.token) {
      verifyEmail.mutate(
        {
          token: search.token,
        },
        {
          onSuccess: () => {
            navigate({
              to: "/$test-slug/home",
              params: {
                "test-slug": "2024-25",
              },
            });
          },
          onError: () => {
            alert("Invalid token");
          },
        }
      );
    }
    // Only want to run on initial mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthChrome>
      <Text textStyle="headingLarge">Verification Email Sent</Text>
      <Text textStyle="body">
        Please check the inbox for <strong>{loggedInUser.data?.email}</strong>{" "}
        and click the verification link within the next 15 minutes, then come
        back and refresh this page.
      </Text>
      <Text textStyle="body">
        Not seeing an email? Make sure to check your spam folder
      </Text>
      <Row gap={16}>
        <Button
          disabled={logout.isPending}
          text="Log Out"
          onPress={() => logout.mutate()}
        />
        <Button
          disabled={requestVerificationEmail.isPending}
          text="Send Again"
          onPress={() => requestVerificationEmail.mutate({})}
        />
      </Row>
    </AuthChrome>
  );
}
