var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { usePopper } from "react-popper";
import Text from "./text";
import { useDesignTokens } from "../lib/design-tokens";
var Tooltip = function (_a) {
    var children = _a.children, style = _a.style, content = _a.content;
    var designTokens = useDesignTokens();
    var _b = useState(false), visible = _b[0], setVisible = _b[1];
    var _c = useState(null), referenceElement = _c[0], setReferenceElement = _c[1];
    var _d = useState(null), popperElement = _d[0], setPopperElement = _d[1];
    var _e = useState(null), arrowElement = _e[0], setArrowElement = _e[1];
    var _f = usePopper(referenceElement, popperElement, {
        modifiers: [{ name: "arrow", options: { element: arrowElement } }],
        placement: "top",
    }), styles = _f.styles, attributes = _f.attributes;
    return (_jsxs(_Fragment, { children: [_jsx("span", { onMouseEnter: function () { return setVisible(true); }, onMouseLeave: function () { return setVisible(false); }, style: style, ref: setReferenceElement, children: children }), visible && content !== undefined && (_jsxs("div", __assign({ ref: setPopperElement, style: __assign({ backgroundColor: designTokens.colors.surface.White, borderRadius: 8, padding: 16, maxWidth: 256, boxShadow: "0px 6px 20px 0px rgba(0, 0, 0, 0.12), 0px 0px 2px 0px rgba(0, 0, 0, 0.16)" }, styles.popper) }, attributes.popper, { children: [typeof content === "string" ? (_jsx(Text, { textStyle: "body", children: content })) : (content), _jsx("div", { ref: setArrowElement, style: styles.arrow })] })))] }));
};
export default Tooltip;
