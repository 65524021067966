const config: Record<string, unknown> = {};

// To add an env var, just add it as a key to this object with the value as an
// empty string. It's a little bit hacky since using TS types at runtime is
// weird.
const envVarKeys = {
  VITE_API_URL: "",
  VITE_OFFLINE_DEVELOPMENT_MODE: false,
  VITE_STRIPE_PUBLISHABLE_KEY: "",
  VITE_TENANT_ID: "",
  VITE_TEST_SLUG: "",
  VITE_TENANT_THEME_COLOR: "",
  VITE_MOCK_DATE: "",
  VITE_NODE_ENV: "",
};

Object.keys(envVarKeys).forEach((key) => {
  const envVar = (import.meta as any).env[key];
  if (typeof envVar !== "string") {
    throw new Error(
      `Environment variable ${key} is missing. Did you make a file called .env?`
    );
  } else {
    if (typeof envVarKeys[key as keyof typeof envVarKeys] === "boolean") {
      if (envVar.toLowerCase() === "true") {
        config[key] = true;
      } else if (envVar.toLowerCase() === "false") {
        config[key] = false;
      } else {
        throw new Error(
          `Environment variable ${key} is supposed to be a boolean, but it is ${envVar}. Please set it to either true or false.`
        );
      }
    } else {
      config[key] = envVar;
    }
  }
});

export default config as typeof envVarKeys;
